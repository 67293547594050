@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background-color: #2d2727;
}

@font-face {
  font-family: "ACROM";
  src: url("../public/fonts/Acrom-Regular.woff2");
}
@font-face {
  font-family: "ACROMEXTRABOLD";
  src: url("../public/fonts/Acrom-ExtraBold.woff2");
}
@font-face {
  font-family: "ACROMLIGHT";
  src: url("../public/fonts/Acrom-Light.woff2");
}
@font-face {
  font-family: "ACROMMEDIUM";
  src: url("../public/fonts/Acrom-Medium.woff2");
}
@font-face {
  font-family: "ACROMTHIN";
  src: url("../public/fonts/Acrom-Thin.woff2");
}
@font-face {
  font-family: "ACROMBOLD";
  src: url("../public/fonts/Acrom-Bold.woff2");
}

@font-face {
  font-family: "THESEASONS";
  src: url("../public/fonts/theseasons-reg.otf");
}
@font-face {
  font-family: "THESEASONSBOLD";
  src: url("../public/fonts/theseasons-bd.otf");
}
@font-face {
  font-family: "THESEASONSBOLDIT";
  src: url("../public/fonts/theseasons-bdit.otf");
}
@font-face {
  font-family: "THESEASONSIT";
  src: url("../public/fonts/theseasons-it.otf");
}
@font-face {
  font-family: "THESEASONSLT";
  src: url("../public/fonts/theseasons-lt.otf");
}
@font-face {
  font-family: "THESEASONSLTIT";
  src: url("../public/fonts/theseasons-ltit.otf");
}
